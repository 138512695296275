<template>
  <div v-loading.fullscreen.lock="loading" id="settings_change_password">
    <inner-page-title title="Change password"></inner-page-title>

    <div v-if="info_type == 'set_password'" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'unlock-alt']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">We need some details</span>
      <p>In order to change your password we need to verify it's you. Please complete the following form.</p>

      <br>

      <el-input v-if="has_password_already" type="password"  style="margin-bottom: 10px" v-model="old_password" placeholder="Old password"></el-input>
      
      <el-input type="password"  style="margin-bottom: 10px" v-model="new_password" placeholder="New password"></el-input>

      <el-input type="password"  style="margin-bottom: 10px" v-model="new_password_repeat" placeholder="Repeat new password"></el-input>

      <div style="margin-top: 20px; display: flex; align-items: center; justify-content: center">
        <el-button type="primary" @click="onChangePassword" round class="secondary-color">Update password</el-button>
      </div>
    </div>

    <div style="text-align: center" v-if="info_type == 'reauthenticate'" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'sign-out-alt']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You need to re-login</span>
      <p>Your password hasn't been changed yet. To make sure it's you, we need you to logout and re-login before trying to change your password again. You can logout by clicking below.</p>

      <el-button style="margin-top: 20px;" type="primary" class="secondary-color" @click="logout">Logout</el-button>
    </div>

    <div style="text-align: center" v-if="info_type == 'password_changed'" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'check-double']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Password changed</span>
      <p>Your password has been changed successfully. From now, you can start using your new password.</p>
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase';
import _firebase from 'firebase/app';
import 'firebase/auth';
import FormItem from '@/components/Form/FormItem';

export default {
  components: { FormItem },
  data: () => ({
    loading: true,
    old_password: '',
    new_password: '',
    new_password_repeat: '',
    has_password_already: false,
    info_type: 'set_password', /* 'set_password', 'reauthenticate', 'password_changed' */
    error_message: "This operation is sensitive and requires recent authentication. Log in again before retrying this request."
  }),

  methods: {
    logout() {
      this.$store.dispatch('logout').then(result => {
        this.$router.push(`/login?redirectUrl=${encodeURI(this.$route.path)}`).catch(()=>{});
      });
    },

    async onChangePassword() {
      if (this.new_password !== this.new_password_repeat) {
        return this.$message({ type: 'error', showClose: true, message: 'Passwords do not match', duration: 5000 });
      }

      if (this.new_password.length < 6) {
        return this.$message({ type: 'error', showClose: true, message: 'Password needs to be at least 6 characters long', duration: 5000 });
      }

      // this.loading = true;

      // Reauthenticate user first
      if (this.has_password_already) {
        const credential = _firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, this.old_password);
  
        try {
          let reauthResult = await firebase.auth().currentUser.reauthenticateWithCredential(credential);
        } catch (err) {
          return this.$message({ type: 'error', showClose: true, message: 'Your old password is incorrect. Try again or contact admins' });
        }
      }
      

      firebase.auth().currentUser.updatePassword(this.new_password).then(_ => {
        this.info_type = 'password_changed';
      }).catch(err => {
        console.error(err);
        this.error_message = err.message;

        if (err.code == "auth/requires-recent-login") {
          this.info_type = 'reauthenticate';
        }

      }).finally(_ => this.loading = false);
    }
  },

  mounted() {
    firebase.auth().fetchSignInMethodsForEmail(firebase.auth().currentUser.email).then(result => {
      this.has_password_already = result.includes('password');
    }).finally(() => {
      setTimeout(() => this.loading = false, 500);
    });
  }
}
</script>

<style lang="scss" scoped>
  .container.settings-options {
    max-width: 600px;

    .form-item {
      margin-bottom: 10px;
    }
  }

  .image-container {
    border-radius: 50%;
    background-color: #d1f1ff;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin: auto;
    margin-bottom: 15px;
  }

</style>