var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_change_password" }
    },
    [
      _c("inner-page-title", { attrs: { title: "Change password" } }),
      _vm.info_type == "set_password"
        ? _c(
            "div",
            { staticClass: "container settings-options" },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "unlock-alt"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("We need some details")]
              ),
              _c("p", [
                _vm._v(
                  "In order to change your password we need to verify it's you. Please complete the following form."
                )
              ]),
              _c("br"),
              _vm.has_password_already
                ? _c("el-input", {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: { type: "password", placeholder: "Old password" },
                    model: {
                      value: _vm.old_password,
                      callback: function($$v) {
                        _vm.old_password = $$v
                      },
                      expression: "old_password"
                    }
                  })
                : _vm._e(),
              _c("el-input", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: { type: "password", placeholder: "New password" },
                model: {
                  value: _vm.new_password,
                  callback: function($$v) {
                    _vm.new_password = $$v
                  },
                  expression: "new_password"
                }
              }),
              _c("el-input", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: { type: "password", placeholder: "Repeat new password" },
                model: {
                  value: _vm.new_password_repeat,
                  callback: function($$v) {
                    _vm.new_password_repeat = $$v
                  },
                  expression: "new_password_repeat"
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "secondary-color",
                      attrs: { type: "primary", round: "" },
                      on: { click: _vm.onChangePassword }
                    },
                    [_vm._v("Update password")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.info_type == "reauthenticate"
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "sign-out-alt"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("You need to re-login")]
              ),
              _c("p", [
                _vm._v(
                  "Your password hasn't been changed yet. To make sure it's you, we need you to logout and re-login before trying to change your password again. You can logout by clicking below."
                )
              ]),
              _c(
                "el-button",
                {
                  staticClass: "secondary-color",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.logout }
                },
                [_vm._v("Logout")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.info_type == "password_changed"
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "check-double"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("Password changed")]
              ),
              _c("p", [
                _vm._v(
                  "Your password has been changed successfully. From now, you can start using your new password."
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }